import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { ViewsComponent } from "./views/views.component";
import { AuthGuard } from "./_helpers/auth.guard.service";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },

  { path: "", redirectTo: "auth", pathMatch: "full" },
  {
    path: "edit-profile",
    component: EditProfileComponent,
  },
  {
    path: "",
    component: ViewsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "change-password", component: EditProfileComponent },

      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },



      {
        path: "analytics-dashboard",
        loadChildren: () =>
          import("../app/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          )
      },

      {
        path: "regular/users",
        loadChildren: () =>
          import("./views/regular-users/regular-users.module").then(
            (m) => m.RegularUsersModule
          ),
      },

      {
        path: "manage-admins",
        loadChildren: () =>
          import("./views/manage-admins/manage-admins.module").then(
            (m) => m.ManageAdminsModule
          ),
      },

      {
        path: "business/users",
        loadChildren: () =>
          import("./views/business-users/business-users.module").then(
            (m) => m.BusinessUsersModule
          ),
      },

      {
        path: "ad-requests",
        loadChildren: () =>
          import("./views/ad-requests/ad-requests.module").then(
            (m) => m.AdRequestsModule
          ),
      }


    ],
  },
  // { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
