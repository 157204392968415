import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class DashboardService {
    url: any = environment.API_URL;
    header = {};

    constructor(
        private http: HttpClient
    ) { }

    getDashboard(){
        return this.http.get(this.url + "admin/reportingUsers");
    }

    getUsers(data:any){
        return this.http.get(this.url + `admin/allRegisteredUsers?page=${data?.page}`);
    }

}