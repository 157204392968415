import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";

import { ImageCropperModule } from "ngx-image-cropper";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ToastrModule } from "ngx-toastr";
import { NgHttpLoaderModule } from "ng-http-loader";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { ModalModule } from "ngx-bootstrap/modal";
import { CustomFormsModule } from "ng2-validation";

import { NgxSliderModule } from "@angular-slider/ngx-slider";

import { AppRoutingModule } from "./app-routing.module";
import { AuthModule } from "./auth/auth.module";
import { SharedModule } from "./shared/shared.module";

import { AppComponent } from "./app.component";
import { AuthInterceptor } from "./_helpers/auth.interceptor";
import { AuthService } from "./auth/services/auth.service";
import { SharedService } from "./shared/services/shared.service";
import { ViewsComponent } from "./views/views.component";
import { AuthGuard } from "./_helpers/auth.guard.service";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { SessionService } from "./shared/services/session.service";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { MatSelectModule } from "@angular/material/select";
import { DashboardService } from "./dashboard/services/dashboard.service";
import { BnNgIdleService } from 'bn-ng-idle'; // import bn-ng-idle service
import { ManageUsersService } from "./views/regular-users/manage-users.service";
import { BusinessUsersService } from "./views/business-users/business-users.service";
import { AdRequestsService } from "./views/ad-requests/ad-requests.service";
import { ManageAdminsService } from "./views/manage-admins/manage-admins.service";
import { CustomerSubmissionService } from "./views/customer-submission/customer-submission.service";


@NgModule({
  declarations: [
    AppComponent,
    ViewsComponent,
    EditProfileComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule,
    SharedModule,
    ImageCropperModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    ModalModule.forRoot(),
    CustomFormsModule,
    NgxSliderModule,
    NgxIntlTelInputModule,
    MatSelectModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    AuthGuard,
    AuthService,
    SharedService,
    DashboardService,
    SessionService,
    BnNgIdleService,
    ManageUsersService,
    BusinessUsersService,
    AdRequestsService,
    ManageAdminsService,
    CustomerSubmissionService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
