import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HeaderService {
  constructor() { }

  manageEmergencyHeading = new BehaviorSubject(false);
  manageUsersHeading = new BehaviorSubject(false);
  welcomeUserMsg = new BehaviorSubject(false);
  dashboardHeading = new BehaviorSubject(false);
  changePasswordHeading = new BehaviorSubject(false);
  notificationHeading = new BehaviorSubject(false);
  businessUsersHeading = new BehaviorSubject(false);
  revenueGeneratedHeading = new BehaviorSubject(false);
  registeredUsersHeading = new BehaviorSubject(false);
  manageAdminsHeading = new BehaviorSubject(false);
  manageAdsHeading = new BehaviorSubject(false);
  customerSubmissionHeading = new BehaviorSubject(false);


}
