import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class AdRequestsService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getAds(data: any) {
        return this.http.get(this.url + `admin/allAds?page=${data.page}`);
    }

    approveAd(id:any,data:any){
        return this.http.put(this.url + `admin/ads/approve/${id}`, data);
    }

    rejectAd(id:any,data:any){
        return this.http.put(this.url + `admin/ads/reject/${id}`, data);
    }
}