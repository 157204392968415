import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class SharedService {
  url: any = environment.API_URL;
  changePasswordHeading: any;
  dashboardHeading: any;
  manageUsersHeading: any;
  constructor(private http: HttpClient) { }

  activeInactive(id:any, data:any) {
    return this.http.patch(this.url + `admin/${id}/status`,data);
  }

  filterUsers(data:any){
    if(data?.name && data?.startDate && data?.endDate){
      return this.http.get(this.url + `admin/manage-users?name=${data?.name}&startDate=${data?.startDate}&endDate=${data?.endDate}&sortOrder=${data?.order}&role=${data?.role}`);
    }else if(data?.startDate && data?.endDate){
      return this.http.get(this.url + `admin/manage-users?startDate=${data?.startDate}&endDate=${data?.endDate}&sortOrder=${data?.order}&role=${data?.role}`);
    }else if(data?.name){
      return this.http.get(this.url + `admin/manage-users?name=${data?.name}&role=${data?.role}`);
    }
  }

  filterUsersAll(data:any){
    if(data?.name && data?.startDate && data?.endDate){
      return this.http.get(this.url + `admin/manage-users?name=${data?.name}&startDate=${data?.startDate}&endDate=${data?.endDate}&sortOrder=${data?.order}`);
    }else if(data?.startDate && data?.endDate){
      return this.http.get(this.url + `admin/manage-users?startDate=${data?.startDate}&endDate=${data?.endDate}&sortOrder=${data?.order}`);
    }else if(data?.name){
      return this.http.get(this.url + `admin/manage-users?name=${data?.name}`);
    }
  }

  updateAdmin(id:any,data:any){
    return this.http.put(this.url + `admin/update-admin/${id}`, data);
  }

  getUser(id:any){
    return this.http.get(this.url + `admin/get-admin/${id}`);
  }
}
