import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/shared/services/header.service';
import { DashboardService } from '../services/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  dashboardAllData:any;
  constructor(
    private _header: HeaderService, 
    private dashboardService: DashboardService,
    ) { }

  ngOnInit(): void {
    this._header.welcomeUserMsg.next(true);
    this.getDashboardData();
  }

  getDashboardData(){
    this.dashboardService.getDashboard().subscribe((res:any) => {
      this.dashboardAllData = res?.data;
    })
  }

  ngOnDestroy() {
    this._header.welcomeUserMsg.next(false);
  }

}
