import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class ManageUsersService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getUsers(data:any) {
        return this.http.get(this.url + `admin/getAllCustomerUsers?page=${data?.page}`);
    }
}