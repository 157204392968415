import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class ManageAdminsService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) { }

    deleteInterest(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'category/delete', httpOptions);
    }

    createAdmin(data:any){
        return this.http.post(this.url + 'admin/create-admin', data);
    }

    getAdminList(data:any){
        return this.http.get(this.url + `admin/all-Admins?page=${data?.page}&limit=${data?.limit}`, data);
    }

    deleteAdmin(id:any){
        return this.http.delete(this.url + 'admin/delete-admin/'+id);
    }

    updateAdmin(id:any,data:any){
        return this.http.put(this.url + `admin/update-admin/${id}`, data);
    }
    
}