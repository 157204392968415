<app-layout></app-layout>
<div class="content">
    <div class="row mt-4">
            <div class="total-counts">

                <div class="total-count total-careers  col-lg-5 mt-3 card-border" routerLink="/dashboard/users">
                    <img src="../../../assets/dashboard_icons/Active users.svg" alt="" height="60" width="60">
                    <div>
                        <h2 id="totalUsers" class="counter">{{dashboardAllData?.totalUsers ? dashboardAllData?.totalUsers : 0}}</h2>
                        <p>Total Number of Registered Users</p>
                    </div>
                </div>

                <div class="total-count total-users col-lg-5 mt-3 card-border" routerLink="/business/users/list">
                    <img src="../../../assets/dashboard_icons/Devices.svg" alt="" height="60" width="60">
                    <div>
                        <h2 id="client" class="counter">{{dashboardAllData?.businessUsers ? dashboardAllData?.businessUsers : 0}}</h2>
                        <p>Total Business Users</p>
                    </div>
                </div>

                <div class="total-count total-careers  col-lg-5 mt-3 card-border" routerLink="/regular/users/list">
                    <img src="../../../assets/dashboard_icons/Active users.svg" alt="" height="60" width="60">
                    <div>
                        <h2 id="caregiver" class="counter">{{dashboardAllData?.customerUsers ? dashboardAllData?.customerUsers : 0}}</h2>
                        <p>Total Regular Users</p>
                    </div>
                </div>

                <div class="total-count total-gs  col-lg-5 mt-3 card-border" routerLink="/dashboard/revenue">
                    <img src="../../../assets/dashboard_icons/Active age group.svg" alt="" height="60" width="60">
                    <div>
                        <h2 id="booking">$ {{dashboardAllData?.totalProducts ? dashboardAllData?.totalProducts : 0}}</h2>
                        <p>Total Revenue Generated</p>
                    </div>
                </div>

                <!-- <div class="total-count total-gs  col-lg-5 mt-3 card-border" routerLink="/dashboard/product-request">
                    <img src="../../../assets/dashboard_icons/Active age group.svg" alt="" height="60" width="60">
                    <div>
                        <h2 id="booking">{{analyticsData?.requestedProductCount}}</h2>
                        <p>Total Number of Products Requests</p>
                    </div>
                </div>

                <div class="total-count total-resources  col-lg-5 mt-3 card-border" routerLink="/dashboard/most-view-products">
                    <img src="../../../assets/dashboard_icons/View Feedback.svg" alt="" height="60" width="60">
                    <div>
                        <h2 id="income">{{analyticsData?.viewedProducts}}</h2>
                        <p>Most viewed Products per Region, Category</p>
                    </div>
                </div>

                <div class="total-count total-resources  col-lg-5 mt-3 card-border" routerLink="/refund-request/list">
                    <img src="../../../assets/dashboard_icons/View Feedback.svg" alt="" height="60" width="60">
                    <div>
                        <h2 id="income">{{analyticsData?.refundRequestCount}}</h2>
                        <p>Total Number of Refund Requests Received</p>
                    </div>
                </div> -->
            </div>
        </div>


</div>