import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/services/auth.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from 'ngx-toastr';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  submitted: boolean = false;
  modalRef: BsModalRef;
  changed: boolean = false;
  updated: boolean = false;


  hide = true;
  hide1 = true;
  hide2 = true;




  @ViewChild("template") template: TemplateRef<any>;
  token: any;
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private toastr: ToastrService
  ) { }

  resetForm = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern('^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$')]),
    confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern('^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$')])
  })

  ngOnInit(): void {
    this.route.queryParams.subscribe(param => {
      console.log("🚀 ~ file: reset-password.component.ts:41 ~ ResetPasswordComponent ~ ngOnInit ~ param", param)
      this.token = param['token'];
    })
    console.log("🚀 ~ file: reset-password.component.ts:42 ~ ResetPasswordComponent ~ ngOnInit ~ this.token", this.token)
  }

  get f() {
    return this.resetForm.controls;
  }


  myFunction() {
    this.hide = !this.hide;
  }

  myFunction1() {
    this.hide1 = !this.hide1;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }


  sendEmailToLogin: any;

  resetPassword() {
    this.submitted = true;
    if (this.resetForm.invalid || this.resetForm.pristine) return;
    if (this.resetForm.value.password == this.resetForm.value.confirmPassword) {
      this.submitted = false;
      this.authService.resetPassword({ password: this.resetForm.value.password, token: this.token })
        .subscribe((res: any) => {
          if (res.status == "success") {
            this.updated = true
          }
        },
          (err) => {
            this.router.navigateByUrl('/auth/reset-password');
          })
    } else {
      this.toastr.error('NewPassword and ConfirmPassword is not matching');
    }
  }

  OkPress() {
    this.modalRef.hide();
    this.router.navigateByUrl('/login');
  }

}
