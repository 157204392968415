<div id="forgot-password">
    <div class="container-fluid">
        <div class="row" *ngIf="!updated">

            <div class="col-md-12">
                <div class="login-field">
                    <div class="login-box">
                        <div class="logo"><img src="../../../../assets/xodus/left_mf_logo.svg" alt="logo"></div>
                        <h3 style="margin-top: 30px; margin-bottom: 35px;">Reset your Password</h3>
                        <form [formGroup]="resetForm" (submit)="resetPassword()">
                            <div class="form-group">
                                <label for="password">New Password</label>
                                <span class="input-icon"><img src="../../assets/icons/password.svg" alt=""
                                        style="margin-top: -5px;"></span>
                                <input [type]="hide ? 'password' : 'text'" class="form-control" placeholder="password"
                                    id="email" formControlName="password">
                                <button mat-icon-button matSuffix (click)="hide = !hide"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <mat-icon class="placeholder">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <div *ngIf="submitted && f.password.errors" class="alert alert-danger">
                                    <span *ngIf="f.password.errors.required">
                                        password is required.
                                    </span>
                                    <span *ngIf="f.password.errors.password">
                                        Invalid password.
                                    </span>
                                    <span *ngIf="f.password.errors.pattern">
                                        Password should contain min 8 characters and A-Z , a-z, 0-9, !@#$%^&*().
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="password">Confirm Password</label>
                                <span class="input-icon"><img src="../../assets/icons/password.svg" alt=""
                                        style="margin-top: -5px;"></span>
                                <input [type]="hide1 ? 'password' : 'text'" class="form-control" placeholder="password"
                                    id="email" formControlName="confirmPassword">
                                <button mat-icon-button matSuffix (click)="hide1 = !hide1"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
                                    <mat-icon class="placeholder">{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <div *ngIf="submitted && f.confirmPassword.errors" class="alert alert-danger">
                                    <span *ngIf="f.confirmPassword.errors.required">
                                        password is required.
                                    </span>
                                    <span *ngIf="f.confirmPassword.errors.confirmPassword">
                                        Invalid password.
                                    </span>
                                    <span *ngIf="f.confirmPassword.errors.pattern">
                                        Password should contain min 8 characters and A-Z , a-z, 0-9, !@#$%^&*().
                                    </span>
                                </div>
                            </div>
                            <div class="form-group text-center">
                                <button type="submit" class="btn btn-primary"
                                    [disabled]="resetForm.value.password.length < 8 || resetForm.value.confirmPassword.length < 8">Update
                                    Password</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="updated">
            <!-- <div class="logo"><img src="../../../../assets/images/bungie_logo_main.svg" alt="logo"></div> -->
            <div class="col-md-12">
                <div class="login-field">
                    <div class="login-box">
                        <div class="logo"><img src="../../../../assets/xodus/left_mf_logo.svg" alt="logo"></div>
                        <div class="modal-body text-center delete-modal">
                            <img src="../../../../../assets/images/create-successfull-img.svg" alt="">
                            <!-- <img src="../../../../../assets/images/delete-img.svg" alt="" /> -->
                            <!-- <img src="../../../../../assets/icons/reset-password.svg" alt="" style="width: 20%; height:50%"> -->
                            <p>Reset Password Completed. <br />Login again with new password</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- Forget Password -->
    <ng-template #template>
        <div class="modal-header">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body text-center delete-modal">
            <img src="../../../../../assets/images/create-successfull-img.svg" alt="">
            <!-- <img src="../../../../../assets/images/delete-img.svg" alt="" /> -->
            <!-- <img src="../../../../../assets/icons/reset-password.svg" alt="" style="width: 20%; height:50%"> -->
            <p>Reset Password<br />Completed</p>
            <div class="modal-btn">

                <button class="btn btn-cancel btn-primary" (click)="OkPress()"
                    style="background-color: #346ef6;">OK</button>
                <!-- <button class="btn btn-confirm" (click)="updatePassword()">Confirm</button> -->
            </div>
        </div>
    </ng-template>