import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { AuthService } from "src/app/auth/services/auth.service";
import { ToastrService } from "ngx-toastr";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SharedService } from "../services/shared.service";
import { HeaderService } from "../services/header.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  manageServicesHeading: boolean = false;
  manageUsersHeading: boolean = false;
  welcomeUserMsg: boolean = false;
  changePasswordHeading: boolean = false;
  manageEmergencyHeading: boolean = false;
  notificationHeading: boolean = false;

  dashboardHeading: boolean = false;
  businessUsersHeading: boolean = false;
  revenueGeneratedHeading: boolean = false;
  registeredUsersHeading: boolean = false;
  manageAdminsHeading: boolean = false;
  manageAdsHeading: boolean = false;

  @ViewChild("template") template: TemplateRef<any>;
  @ViewChild("template1") template1: TemplateRef<any>;


  default: boolean;
  lang: string;

  update: boolean = false;
  view: boolean = false;
  submit: boolean = true;
  del: boolean = false;

  profilePic: String = "";
  modalRef: any;
  imgURL: string;
  viewData: any;
  userData: any;
  error: string;
  submitted:boolean = false;

  constructor(
    private shared: SharedService,
    private _header: HeaderService,

    public router: Router,
    private modalService: BsModalService,
    private authService: AuthService,
    private toastrService: ToastrService
  ) {


    this._header.manageUsersHeading.subscribe((res) => {
      this.manageUsersHeading = res;
    });

    this._header.dashboardHeading.subscribe((res) => {
      this.dashboardHeading = res;
    });

    this._header.changePasswordHeading.subscribe((res) => {
      this.changePasswordHeading = res;
    });

    this._header.manageEmergencyHeading.subscribe((res) => {
      this.manageEmergencyHeading = res;
    });

    this._header.notificationHeading.subscribe((res) => {
      this.notificationHeading = res;
    });

    this._header.businessUsersHeading.subscribe((res) => {
      this.businessUsersHeading = res;
    });

    this._header.revenueGeneratedHeading.subscribe((res) => {
      this.revenueGeneratedHeading = res;
    });

    this._header.registeredUsersHeading.subscribe((res) => {
      this.registeredUsersHeading = res;
    });

    this._header.manageAdminsHeading.subscribe((res) => {
      this.manageAdminsHeading = res;
    });

    this._header.manageAdsHeading.subscribe((res) => {
      this.manageAdsHeading = res;
    });


  }
  interestForm: FormGroup;
  userName: String;
  firstName: String;
  lastName: String;
  allDate: any;
  currentUser: any;
  ngOnInit(): void {
    this.interestForm = new FormGroup({
      email: new FormControl({ value: '', disabled: true }),
      firstName: new FormControl("", Validators.required),
      lastName: new FormControl("", Validators.required),
      phoneNumber: new FormControl("", [Validators.minLength(7), Validators.required]),
    });
    this.currentUser = this.authService.currentUserValue;
    this.default = true;
    this.lang = "English";
    this.profilePic = "../../../assets/icons/user-img.svg";
    // this.getUserProfile();
  }

  get f() {
    return this.interestForm.controls;
  }

  reset() {
    this.imgURL = "../../../assets/icons/img-thumb.svg";
    this.interestForm.reset();
  }

  getUserProfile(): void {
    this.shared.getUser(this.currentUser?.userId).subscribe(
      (res: any) => {
          this.userData = res?.data?.admin;
          this.editInterest();
      }
    );
  }

  edit(): void {

    this.submitted = true;
    if(this.interestForm.invalid) return
    this.submitted = false;
    let data = {
        "firstName": this.interestForm.value.firstName,
        "lastName": this.interestForm.value.lastName,
        "phone": this.interestForm.value.phoneNumber
    }

    this.shared.updateAdmin(this.currentUser?.userId, data).subscribe(
      (res: any) => {
          this.modalRef.hide();
          this.reset();
          this.toastrService.success("Updated Successfully");
      },
      (err) => {
        this.toastrService.error(err?.error?.message);
      }
    );
  }

  editPopup() {
    this.editInterest();
    this.getUserProfile();
  }

  editInterest() {
    this.view = false;
    this.update = true;
    this.del = false;
    this.interestForm.setValue({
      email: this.userData.email ? this.userData.email : "",
      firstName: this.userData.firstName ? this.userData.firstName : "",
      lastName: this.userData.lastName ? this.userData.lastName : "",
      phoneNumber: this.userData.phone ? this.userData.phone : "",
    });
  }

  // modal lougout confirmation
  openModal(template: TemplateRef<any>) {
    this.update = true;
    this.modalRef = this.modalService.show(template);
    this.getUserProfile();
  }

  openLogoutModal(template1: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template1);
  }

  confirm() {
    this.logout();
  }

  logout() {
    this.modalRef.hide();
    this.router.navigateByUrl("/auth/login");
    localStorage.clear();
  }

  cancel() {
    this.modalRef.hide();
  }

  numberOnly(evt: any) {
    let charCode = evt.which ? evt.which : evt.keyCode;
    if(charCode>=48 && charCode<=57){
      return true
    }else {
      return false;
    }
  }

  charOnly(evt: any) {
    let charCode = evt.which ? evt.which : evt.keyCode;
    console.log(charCode)
    if(charCode>=65 && charCode<=90 || charCode>=97 && charCode<=122 || charCode==32){
      return true
    }else {
      return false;
    }
  }
}
