import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class CustomerSubmissionService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getUsers(data:any) {
        return this.http.get(this.url + `admin/customer-submissions-list?limit=${data.limit}&page=${data.page}`);
    }

    getBusinessPayload(id:any) {
        return this.http.get(this.url + `business/payload/${id}`);
    }
}